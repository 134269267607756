.page-expired-link {
	height: 100%;
	background-color: $c-dark-navy;
	background-size: cover;
	background-position: center;
	background-image: url("../../../assets/images/hero-background.svg");

	.expired-link {
		height: 100%;
		display: flex;

		&__container {
			width: 50%;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;

			@include max-width($scr-sm) {
				width: 100%;

				&:first-child {
					display: none;
				}
			}
		}

		&__hero-image {
			width: 100%;
			max-width: 500px;
			padding: 20px;

			img {
				width: 100%;
			}
		}

		&__logo {
			width: 230px;
			margin-bottom: 15px;

			img {
				width: 100%;
				display: block;
			}
		}

		&__title {
			margin-bottom: 30px;
			color: $c-white;
			font-size: $text-size-l;
		}

		&__sub-title {
			color: $c-white;
		}

		&__description {
			width: 100%;
			max-width: 400px;
			margin: 30px 0;
			color: $c-white;
			text-align: center;
			font-size: $text-size-body;
			@extend .font-thin;
		}
	}
}
