/* -- Color -- */
$c-white: #ffffff;
$c-black: #000000;
$c-light-black: #212529;
$c-body: #f3f3f6;
$c-gray: #919191;
$c-dark-navy: #2b398a;
$c-dark-green: #5cb30b;
$c-light-navy: #e1ebf9;
$c-dark-gray: #a3a6ac;
$c-light-gray: #eeeeee;
$c-yellow: #e3d900;
$c-cyan: #4cbadf;
$c-red: #ff0000;
$c-green: #08d175;
$c-warning: #e3d900;

/* -- Font size -- */
$text-size-xxxl: 48px;
$text-size-xxl: 32px;
$text-size-xl: 28px;
$text-size-l: 22px;
$text-size-m: 18px;
$text-size-body: 16px;
$text-size-s: 14px;
$text-size-xs: 12px;
$text-size-xxs: 10px;

/* -- Font weight -- */
$text-weight-strong: 900;
$text-weight-exbold: 800;
$text-weight-bold: 700;
$text-weight-semibold: 600;
$text-weight-normal: 500;
$text-weight-regular: 400;
$text-weight-thin: 300;
$text-weight-light: 200;
$text-weight-exlight: 100;

/* -- Media query -- */
$scr-lg: "1199px";
$scr-md: "991px";
$scr-sm: "767px";
$scr-xs: "679px";
