.app-upload-input {
    width: 100%;
    min-width: 300px;

    .upload-input {
        &__container {
            width: 100%;
        }

        &__content {
            min-height: 40px;
            margin: 8px 0;
            padding: 0.375rem 0.75rem;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        &__cta {
            border: 0;
            padding: 0;
            background-color: transparent;

            .upload-input__text {
                white-space: nowrap;
                color: $c-dark-green;
                font-size: $text-size-s;
                text-decoration: underline;

                &:before {
                    content: "";
                    width: 20px;
                    height: 20px;
                    margin: 0 10px 0 0;
                    background-size: contain;
                    background-position: center;
                    background-repeat: no-repeat;
                    background-image: url("../../assets/images/upload-icon.svg");
                    flex-shrink: 0;
                }
            }

            &--remove {
                display: flex;
                align-self: flex-start;

                .upload-input__text {
                    &:before {
                        background-image: url("../../assets/images/remove-icon.svg");
                    }
                }
            }
        }

        &__text {
            margin: 0;
            display: flex;
            align-items: center;
            text-align: left;
            color: $c-black;

            &--name {
                margin-right: 20px;
                color: $c-dark-navy !important;
                font-size: $text-size-s;
                word-break: break-word;
                text-decoration: underline;
                white-space: normal !important;

                &:before {
                    display: none;
                }
            }

            &--note {
                margin-left: 20px;
                font-size: $text-size-xs;
                color: lighten($c-gray, 20%);
            }

            &--error {
                margin: 5px 0 0;
                font-size: $text-size-xs;
                color: $c-red;
            }
        }
    }
}
