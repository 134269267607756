.page-faqs {
	padding: 40px;

	.faqs {
		//
		&__title {
			margin: 0;
			color: $c-dark-navy;
			font-size: $text-size-xl;
			@extend .font-semibold;
		}

		&__header {
			width: 100%;
			padding: 20px 0;
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		&__description {
			margin: 0;
			color: $c-gray;
			font-size: $text-size-s;
		}

		&__new-cta {
			height: 41px;
			margin-left: 25px;
			color: $c-dark-green;
			font-size: $text-size-s;
			border-radius: 5px;
			border: 2px solid $c-dark-green;
			background-color: $c-white;
			display: flex;
			align-items: center;
			justify-content: center;

			&:disabled {
				color: lighten($c-gray, 30%);
				border: 2px solid lighten($c-gray, 30%);
			}
		}

		&__label {
			margin: 0;
			padding-right: 10px;
			white-space: nowrap;
		}

		.accordion {
			&--active {
				.accordion__icon--toggle {
					transform: rotate(180deg);
				}
			}

			&--editable {
				.accordion__form {
					display: block;
				}
			}

			&__wrapper {
				width: 100%;
				display: flex;
				align-items: center;
			}

			&__tab {
				width: 100%;
				padding: 15px;
				margin-bottom: 2px;
				background-color: $c-cyan;
				display: flex;
				align-items: center;
				justify-content: space-between;
			}

			&__action-cta {
				width: 35px;
				border: 0;
				background-color: transparent;
			}

			&__icon {
				margin: 0;
				color: $c-white;
				font-size: $text-size-xs;
				cursor: pointer;

				&--edit {
					path {
						fill: $c-white;
					}
				}

				&--remove {
					path {
						fill: $c-white;
						stroke: $c-white;
					}
				}
			}

			&__title {
				margin: 0;
				color: $c-white;
				font-size: $text-size-s;
			}

			&__description {
				padding: 15px;
				font-size: $text-size-s;
			}

			&__action {
				display: flex;
			}

			&__form {
				display: none;
			}
		}
	}
}
