.app-lightbox-image {
	//
	.lightbox-image {
		border: 0;
		background-color: transparent;

		&__header {
			padding: 0 15px;
			border: 0;

			.btn-close {
				filter: invert(1);
			}
		}

		&__close-cta {
			border: 0;
			padding: 0;
			background-color: transparent;
		}

		&__close-icon {
			width: 30px;
			height: 30px;
			color: $c-white;
		}

		&__body {
			width: 100%;
			min-width: 300px;

			img {
				width: 100%;
			}
		}
	}
}
