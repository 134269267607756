.app-date-input {
	width: 100%;
	min-width: 300px;
	margin: 8px 0;

	&__container {
		border-radius: 5px;
		background-color: $c-white;
		display: flex;
		align-items: center;
		border: 1px solid $c-light-gray;

		&--disabled {
			border: 0;

			.app-date-input__icon {
				display: none;
			}
		}

		&--error {
			border: 1px solid $c-red;
		}
	}

	&__input {
		height: 40px;
		width: 100%;
		border: 0;
		outline: none;
		box-shadow: none;

		&:disabled {
			background-color: transparent;
		}

		&:focus {
			box-shadow: none;
		}

		&::placeholder {
			color: $c-gray;
			font-size: $text-size-s;
		}
	}

	&__icon {
		width: 20px;
		height: 20px;
		margin: 0 10px;
		color: $c-dark-green;
		font-size: $text-size-l;
		display: flex;
		align-items: center;
	}

	&__toggle-icon {
		@extend .app-input__icon;
		padding: 0 10px 0 15px;
		cursor: pointer;
	}

	&__text {
		margin: 2.5px 0 0;
		color: $c-gray;
		font-size: $text-size-xs;

		&--error {
			color: $c-red;
		}
	}

	.react-datepicker {
		border: 0;
		padding: 15px;
		border-radius: 10px;
		background-color: #f7f7f7;
		box-shadow: rgba(38, 57, 77, 0.5) 0px 0px 30px -10px;
		@extend .font-normal;

		&__triangle {
			display: none;
		}

		&__navigation {
			top: 20px;

			&--previous {
				left: 17px;
			}

			&--next {
				right: 17px;
			}
		}

		&__navigation-icon {
			//
			&:before {
				border-color: $c-black;
				border-width: 2px 2px 0 0;
			}
		}

		&__month-container {
			float: inherit;
			width: 100%;
		}

		&__header {
			padding: 10px 0;
			border: 0;
			background-color: transparent;

			&__dropdown {
				&--scroll {
					padding: 10px;
				}
			}
		}

		&__current-month {
			@extend .font-semibold;
		}

		&__day-names {
			padding: 10px 0;
		}

		&__day-name {
			margin: 2.5px;
			width: 40px;
			color: $c-gray;
			@extend .font-medium;
		}

		&__day {
			margin: 2.5px;
			width: 40px;
			height: 40px;
			line-height: 40px;
			border-radius: 100px;
			font-size: $text-size-s;

			&--selected {
				background-color: $c-dark-green;
			}

			&--keyboard-selected {
				background-color: $c-dark-green;
			}
		}

		&__month {
			margin: 0;
			padding: 0;
		}

		&__year-dropdown-container {
			&--select {
				width: 100%;
				margin-top: 15px;
				padding: 5px 10px;
				background-color: $c-white;
				border: 1px solid lighten($c-gray, 30%);
			}
		}

		&__year-select {
			width: 100%;
			border: 0;

			&:focus {
				outline: none;
			}
		}
	}
}
