.page-customer {
	padding: 40px;

	.customer {
		//
		&__title {
			margin: 0;
			padding-bottom: 15px;
			color: $c-dark-navy;
			font-size: $text-size-xl;
			@extend .font-semibold;
		}

		&__list {
			margin-bottom: 25px;
			padding: 15px 0;
			display: flex;
			flex-wrap: wrap;
			align-items: center;
		}

		&__item {
			position: relative;
			margin-right: 25px;
			margin-bottom: 20px;
			color: lighten($c-gray, 30%);
			list-style: none;
			transition: 200ms ease-in-out 25ms;
			cursor: pointer;
			@extend .font-medium;

			a {
				color: lighten($c-gray, 30%);
				text-decoration: none;
			}

			&:before {
				content: "";
				position: absolute;
				width: 0;
				height: 2px;
				left: 0;
				right: 0;
				bottom: -10px;
				margin: 0 auto;
				background-color: $c-dark-green;
				transition: 200ms ease-in-out 25ms;
			}

			&--active {
				a {
					animation: show-text-highlight 0.5s ease forwards;
				}

				&:before {
					animation: show-highlight 0.5s ease forwards;
				}
			}
		}

		&__profile {
			padding: 25px;
			background-color: $c-light-gray;
			display: flex;
			align-items: center;
		}

		&__profile-image {
			position: relative;
			width: 90px;
			height: 90px;
			border-radius: 100px;
			background-size: cover;
			background-position: center;
			background-repeat: no-repeat;
			background-color: $c-gray;
			z-index: 999;
			cursor: pointer;

			&:after {
				content: "";
				position: absolute;
				bottom: 0;
				right: 0;
				width: 25px;
				height: 25px;
				display: block;
				background-size: contain;
				background-position: center;
				background-repeat: no-repeat;
				background-image: url("../../../assets/images/pages/page-customers/profile-icon.svg");
			}
		}

		&__actions {
			position: absolute;
			display: none;
			padding: 0;
			margin: 0;
			top: calc(100% + 15px);
			right: -65px;
			width: 200px;
			box-shadow: rgba(38, 57, 77, 0.5) 0px 0px 30px -10px;

			&--active {
				display: block;
			}

			&:before {
				position: absolute;
				display: block;
				content: "";
				top: -10px;
				width: 0;
				height: 0;
				right: 50%;
				transform: translateC(-50%);
				border-left: 10px solid transparent;
				border-right: 10px solid transparent;
				border-bottom: 10px solid $c-dark-green;
			}
		}

		&__action-item {
			padding: 10px;
			list-style-type: none;
			font-size: $text-size-s;
			background-color: $c-white;

			&:first-child {
				color: $c-white;
				background-color: $c-dark-green;
			}
		}

		&__name {
			margin: 0 20px;
			font-size: $text-size-l;
			@extend .font-bold;
		}

		&__content {
			width: 100%;
			max-width: 768px;
			margin: 25px 0;
		}

		&__field {
			position: relative;
			width: 100%;
			margin-bottom: 15px;
			display: flex;
		}

		&__label {
			position: relative;
			width: 200px;
			height: 40px;
			margin: 8px 15px 8px 0;
			padding: 0.375rem 20px 0.375rem 0;
			color: $c-gray;
			white-space: break-spaces;
			display: flex;
			align-items: center;
			flex-shrink: 0;

			&--important {
				&:after {
					content: "*";
					color: $c-red;
				}
			}

			&:before {
				position: absolute;
				right: 5px;
				content: ":";
				display: block;
			}
		}

		&__points {
			margin: 20px 0.75rem 0;
		}

		&__top-up {
			margin-top: 20px;
		}

		&__top-up-cta {
			border: 0;
			padding: 0;
			background-color: transparent;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		&__top-up-icon {
			width: 17px;
			height: 17px;
		}

		&__top-up-label {
			margin: 0 0 0 10px;
			color: $c-dark-green;
			font-size: $text-size-s;
			@extend .font-semibold;
		}

		&__button-container {
			width: 100%;
			max-width: 768px;
			margin-top: 45px;
			display: flex;
			align-items: flex-end;
			justify-content: flex-end;

			.app-cta {
				width: 200px;
				margin-left: 15px;
				min-width: inherit;
			}
		}
	}
}

@keyframes show-highlight {
	0% {
		width: 0;
	}
	100% {
		width: 35px;
	}
}

@keyframes show-text-highlight {
	0% {
		color: lighten($c-gray, 30%);
	}
	100% {
		color: $c-dark-green;
	}
}
