.app-category-form {
	padding-left: 46px;
	min-width: 600px;
	max-width: 768px;

	.category-form {
		padding: 15px;
		border: 0;
		border-radius: 10px;

		&__label {
			position: relative;
			width: 200px;
			height: 40px;
			margin: 8px 15px 8px 0;
			padding: 0.375rem 20px 0.375rem 0;
			color: $c-gray;
			white-space: break-spaces;
			display: flex;
			align-items: center;
			flex-shrink: 0;

			&--important {
				&:after {
					content: "*";
					color: $c-red;
				}
			}

			&:before {
				position: absolute;
				right: 5px;
				content: ":";
				display: block;
			}
		}

		&__field {
			position: relative;
			margin-bottom: 15px;
			display: flex;

			.app-input {
				max-width: 100%;
			}
		}

		&__upload-container {
			width: 100%;
		}

		&__upload-content {
			border: 0;
			min-height: 40px;
			margin: 8px 0;
			padding: 0.375rem 0.75rem;
			background-color: transparent;
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		&__upload-cta,
		&__view-cta {
			border: 0;
			padding: 0;
			background-color: transparent;
		}

		&__text {
			margin: 0;
			display: flex;
			align-items: center;
			text-align: left;
			font-size: $text-size-xs;
			color: lighten($c-gray, 20%);

			&--name {
				color: $c-dark-navy;
				font-size: $text-size-s;
				text-decoration: underline;
				white-space: normal;
				word-break: break-word;
			}

			&--upload,
			&--remove {
				color: $c-dark-green;
				font-size: $text-size-s;
				text-decoration: underline;

				&:before {
					content: "";
					display: block;
					width: 20px;
					height: 20px;
					margin: 0 10px 0 0;
					background-size: contain;
					background-position: center;
					background-repeat: no-repeat;
					background-image: url("../../../../assets/images/upload-icon.svg");
				}
			}

			&--remove {
				&:before {
					background-image: url("../../../../assets/images/remove-icon.svg");
				}
			}

			&--error {
				margin: 5px 0 0;
				color: $c-red;
			}
		}

		&__button-container {
			padding-bottom: 40px;
			border: 0;
			display: flex;
			flex-wrap: nowrap;
			flex-direction: row;
			justify-content: flex-end;

			.app-cta {
				width: 100%;
				margin-left: 10px;
				max-width: 150px;
				min-width: 150px;
			}
		}
	}
}
