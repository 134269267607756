.app-pagination {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__description {
        margin: 0;
        color: $c-gray;
        font-size: $text-size-s;
    }

    &__controller {
        margin: 0;
        color: $c-gray;
        font-size: $text-size-s;
        white-space: break-spaces;

        select {
            height: 30px;
            margin: 0 10px;
            padding: 0 10px;
            color: $c-gray;
            border-radius: 5px;
            border: 1px solid $c-light-gray;
            outline: none;
        }
    }

    &__action {
        margin-left: 15px;
    }

    &__action-cta {
        border: 0;
        background-color: transparent;
    }

    &__arrow {
        margin: 0 5px;
        color: $c-gray;
        pointer-events: none;
    }
}
