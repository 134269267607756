.app-nav-header {
	width: 100%;
	height: 80px;
	background-color: $c-light-navy;

	.nav-header {
		height: 100%;
		padding: 10px 30px;
		display: flex;
		align-items: center;
		justify-content: space-between;

		&__list {
			margin: 0;
			padding: 0;
			display: flex;
			align-items: center;
		}

		&__item {
			padding: 2px 10px;
			list-style-type: none;
			display: flex;
			align-items: center;

			&:last-child {
				border-left: 1px solid $c-dark-navy;
			}
		}

		&__profile {
			width: 40px;
			height: 40px;
			border-radius: 100px;
			overflow: hidden;
			background-color: $c-dark-gray;
			background-size: cover;
			background-position: center;
			background-repeat: no-repeat;
			flex-shrink: 0;
		}

		&__title {
			margin: 0;
			color: $c-dark-navy;
			font-size: $text-size-l;
			@extend .font-semibold;
		}

		&__name {
			margin: 0 0 0 15px;
			color: $c-dark-navy;
			font-size: $text-size-s;
			@extend .font-semibold;
		}

		&__actions {
			display: flex;
			align-items: center;
			justify-content: center;

			button {
				width: 30px;
				height: 30px;
				margin: 0 5px;
				border: 0;
				border-radius: 100px;
				background-color: transparent;
				display: flex;
				align-items: center;
				justify-content: center;

				&:hover {
					background-color: darken($c-light-navy, 3%);
				}

				img {
					width: 100%;
				}
			}
		}

		&__notification {
			position: relative;
		}

		&__alert {
			position: absolute;
			top: 8px;
			right: 8px;
			width: 5px;
			height: 5px;
			border-radius: 5px;
			background-color: $c-red;
		}

		&__switch-language {
			display: flex;
			align-items: center;
			@extend .font-medium;
		}

		&__languages {
			margin: 0;
			color: $c-dark-navy;
			font-size: $text-size-s;
			cursor: pointer;

			span {
				margin: 0 1px;
				color: $c-gray;

				&.nav-header__language-active {
					color: $c-dark-navy;
				}
			}
		}
	}
}
