.app-time-input {
	width: 100%;
	min-width: 300px;
	margin: 8px auto;

	.time-input {
		display: flex;
		align-items: center;

		&__container {
			width: 50%;
			border-radius: 5px;
			background-color: $c-white;
			border: 1px solid $c-light-gray;
			display: flex;
			align-items: center;

			&--disabled {
				border: 0;
				pointer-events: none;
			}

			&--error {
				border: 1px solid $c-red;
			}

			&.show {
				//
				.time-input__toggle {
					color: $c-gray;

					&--active {
						color: $c-light-black;
					}
				}
			}
		}

		&__toggle {
			width: 100%;
			height: 40px;
			color: $c-gray;
			text-align: left;
			font-size: $text-size-s;
			background-color: $c-white !important;
			border: 1px solid $c-light-gray;
			box-shadow: none;
			border: 0;
			display: flex;
			align-items: center;
			justify-content: space-between;

			&:focus {
				outline: none !important;
				box-shadow: none !important;
			}

			&:hover,
			&:active {
				color: $c-gray;
				box-shadow: none !important;
			}

			&:disabled {
				opacity: 1;
				color: $c-gray;
				background-color: $c-white;
			}

			&--active {
				color: $c-light-black;
			}
		}

		&__divider {
			width: 50px;
		}

		&__list {
			width: 100%;
			padding: 0;
			border: 2px solid $c-dark-green;
		}

		&__item {
			padding: 15px;
			color: $c-gray;
			font-size: $text-size-s;

			&:hover {
				color: $c-white;
				background-color: $c-dark-green;
			}
		}

		&__text {
			margin: 2.5px 0 0;
			color: $c-gray;
			font-size: $text-size-xs;

			&--error {
				color: $c-red;
			}
		}

		&__error-wrapper {
			width: 100%;
			display: flex;

			.time-input__text--error {
				width: 50%;

				&:first-child {
					margin-right: 50px;
				}
			}
		}
	}
}
