.app-radio-input {

	.radio-input {
		height: 42px;
		margin: 8px 0;
		padding: 0.375rem 0.75rem;
		display: flex;
		align-items: center;

		&__button {
			padding: 0;
			margin-right: 20px;
			border: 0;
			background-color: transparent;
			display: flex;
			align-items: center;
			justify-content: center;

			&--selected {
				.radio-input__label,
				.radio-input__icon {
					color: $c-green !important;
				}
			}
		}

		&__label {
			margin: 0;
			color: $c-gray;
			font-size: $text-size-s;
		}

		&__text {
			margin: 2.5px 0 0;
			color: $c-gray;
			font-size: $text-size-xs;

			&--error {
				color: $c-red;
			}
		}

		&__icon {
			width: 20px;
			height: 20px;
			margin: 0 10px;
			color: $c-light-gray;
		}
	}
}
