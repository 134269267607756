.app-layout {
	height: 100vh;
	min-width: 768px;
	display: flex;

	@include max-width($scr-sm) {
		height: 100%;
	}

	&__main {
		width: 100%;
		height: 100%;
		overflow-y: hidden;
		display: flex;
		flex-direction: column;
		align-items: stretch;
	}

	&__container {
		height: 100%;
		overflow-y: scroll;
		background-color: $c-white;
		scrollbar-width: none;
		-ms-overflow-style: none;

		&::-webkit-scrollbar {
			display: none;
		}
	}
}
