.app-group-edit-form {
	padding-left: 46px;
	min-width: 600px;
	max-width: 768px;

	.group-edit-form {
		border: 0;
		border-radius: 10px;

		&__label {
			position: relative;
			width: 200px;
			height: 40px;
			margin: 8px 15px 8px 0;
			padding: 0.375rem 20px 0.375rem 0;
			color: $c-gray;
			white-space: break-spaces;
			display: flex;
			align-items: center;
			flex-shrink: 0;

			&--important {
				&:after {
					content: "*";
					color: $c-red;
				}
			}

			&:before {
				position: absolute;
				right: 5px;
				content: ":";
				display: block;
			}
		}

		&__field {
			position: relative;
			margin-bottom: 15px;
			display: flex;

			.app-input {
				max-width: 100%;
			}
		}

		&__button-container {
			padding-bottom: 40px;
			border: 0;
			display: flex;
			flex-wrap: nowrap;
			flex-direction: row;
			justify-content: flex-end;

			.app-cta {
				width: 100%;
				margin-left: 10px;
				max-width: 150px;
				min-width: 150px;
			}
		}
	}
}
