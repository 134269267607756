.page-helpdesk {
	padding: 40px;

	.helpdesk {
		//
		&__title {
			margin: 0 0 30px;
			color: $c-dark-navy;
			font-size: $text-size-xl;
			@extend .font-semibold;
		}

		&__description {
			margin: 0 0 15px;
		}

		&__list {
			margin-bottom: 25px;
			padding: 15px 0;
			display: flex;
			flex-wrap: wrap;
			align-items: center;
		}

		&__item {
			position: relative;
			margin-right: 25px;
			color: lighten($c-gray, 30%);
			list-style: none;
			transition: 200ms ease-in-out 25ms;
			cursor: pointer;
			@extend .font-medium;

			a {
				color: lighten($c-gray, 30%);
				text-decoration: none;
			}

			&:before {
				content: "";
				position: absolute;
				width: 0;
				height: 2px;
				left: 0;
				right: 0;
				bottom: -10px;
				margin: 0 auto;
				background-color: $c-dark-green;
				transition: 200ms ease-in-out 25ms;
			}

			&--active {
				a {
					animation: show-text-highlight 0.5s ease forwards;
				}

				&:before {
					animation: show-highlight 0.5s ease forwards;
				}
			}
		}

		&__content {
			width: 100%;
			max-width: 768px;
			margin: 25px 0;
		}

		&__field {
			position: relative;
			width: 100%;
			margin-bottom: 15px;
			display: flex;
		}

		&__label {
			position: relative;
			width: 200px;
			height: 40px;
			margin: 8px 15px 8px 0;
			padding: 0.375rem 20px 0.375rem 0;
			color: $c-gray;
			white-space: break-spaces;
			display: flex;
			align-items: center;
			flex-shrink: 0;

			&--important {
				&:after {
					content: "*";
					color: $c-red;
				}
			}

			&:before {
				position: absolute;
				right: 5px;
				content: ":";
				display: block;
			}
		}

		&__button-container {
			width: 100%;
			max-width: 768px;
			margin-top: 45px;
			display: flex;
			align-items: flex-end;
			justify-content: flex-end;

			.app-cta {
				width: 200px;
				margin-left: 15px;
				min-width: inherit;
			}
		}
	}
}

@keyframes show-highlight {
	0% {
		width: 0;
	}
	100% {
		width: 35px;
	}
}

@keyframes show-text-highlight {
	0% {
		color: lighten($c-gray, 30%);
	}
	100% {
		color: $c-dark-green;
	}
}
