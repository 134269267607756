.page-outlet {
	padding: 40px 40px 100px;

	.outlet {
		//
		&__title {
			margin: 0;
			padding-bottom: 15px;
			color: $c-dark-navy;
			font-size: $text-size-xl;
			border-bottom: 1px solid $c-light-gray;
			@extend .font-semibold;
		}

		&__content {
			width: 100%;
			max-width: 768px;
			padding: 15px 0;
		}

		&__field {
			position: relative;
			width: 100%;
			margin-bottom: 15px;
			display: flex;
		}

		&__upload-container {
			width: 100%;
		}

		&__upload-content {
			margin: 8px 0;
			padding: 0.375rem 0.75rem;
			display: flex;
			flex-direction: column;
		}

		&__upload-cta-container {
			margin: 8px 0;
			padding: 0.375rem 0.75rem;
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		&__upload-cta {
			border: 0;
			padding: 0;
			margin-right: 15px;
			white-space: nowrap;
			background-color: transparent;
		}

		&__view-container {
			display: flex;
			margin-bottom: 5px;
			justify-content: space-between;
		}

		&__view-cta {
			border: 0;
			padding: 0;
			margin: 5px 0;
			background-color: transparent;
		}

		&__delete-cta {
			width: 20px;
			height: 20px;
			margin-left: 10px;
			background-size: contain;
			background-position: center;
			background-repeat: no-repeat;
			background-image: url("../../../assets/images/remove-icon.svg");
			background-color: transparent;
			border: 0;
			display: block;
		}

		&__text {
			margin: 0;
			display: flex;
			align-items: center;
			text-align: left;
			font-size: $text-size-xs;
			color: lighten($c-gray, 20%);

			&--name {
				cursor: pointer;
				color: $c-dark-navy;
				font-size: $text-size-s;
				word-break: break-word;
				white-space: normal;
				text-decoration: underline;
			}

			&--upload {
				color: $c-dark-green;
				font-size: $text-size-s;
				text-decoration: underline;

				&:before {
					content: "";
					display: block;
					width: 20px;
					height: 20px;
					margin: 0 10px 0 0;
					background-size: contain;
					background-position: center;
					background-repeat: no-repeat;
					background-image: url("../../../assets/images/upload-icon.svg");
				}
			}

			&--error {
				margin: 5px 0 0;
				color: $c-red;
			}
		}

		&__label {
			position: relative;
			width: 200px;
			height: 40px;
			margin: 8px 15px 8px 0;
			padding: 0.375rem 20px 0.375rem 0;
			color: $c-gray;
			white-space: break-spaces;
			display: flex;
			align-items: center;
			flex-shrink: 0;

			&--important {
				&:after {
					content: "*";
					color: $c-red;
				}
			}

			&:before {
				position: absolute;
				right: 5px;
				content: ":";
				display: block;
			}
		}

		&__button-container {
			width: 100%;
			max-width: 768px;
			margin-top: 45px;
			display: flex;
			align-items: flex-end;
			justify-content: flex-end;

			.app-cta {
				width: 200px;
				margin-left: 15px;
				min-width: inherit;
			}
		}
	}
}
