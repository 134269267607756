.page-reset-password {
	height: 100%;
	background-color: $c-dark-navy;
	background-size: cover;
	background-position: center;
	background-image: url("../../../assets/images/hero-background.svg");

	.reset-password {
		height: 100%;
		display: flex;

		&__container {
			width: 50%;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;

			@include max-width($scr-sm) {
				width: 100%;

				&:first-child {
					display: none;
				}
			}
		}

		&__hero-image {
			width: 100%;
			max-width: 500px;
			padding: 20px;

			img {
				width: 100%;
			}
		}

		&__logo {
			width: 230px;
			margin-bottom: 15px;

			img {
				width: 100%;
				display: block;
			}
		}

		&__title {
			margin-bottom: 30px;
			color: $c-white;
			font-size: $text-size-l;
		}

		&__sub-title {
			color: $c-white;
		}

		&__guidance {
			width: 100%;
			max-width: 420px;
			margin: 20px 0 30px;
		}

		&__description {
			margin: 0;
			color: $c-white;
			font-size: $text-size-s;
			display: flex;

			svg {
				flex-shrink: 0;
				font-size: $text-size-l;
			}

			&--valid {
				color: $c-green;
			}

			&--in-valid {
				color: $c-red;
			}
		}

		form {
			width: 100%;
			display: flex;
			align-items: center;
			flex-direction: column;
			justify-content: center;

			.app-input {
				max-width: 460px;

				&__input {
					height: 50px;
				}
			}
		}
	}
}
