.page-outlets {
	padding: 40px;

	.outlets {
		//
		&__title {
			margin: 0;
			color: $c-dark-navy;
			font-size: $text-size-xl;
			@extend .font-semibold;
		}

		&__header {
			width: 100%;
			padding: 20px 0;
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		&__description {
			margin: 0;
			color: $c-gray;
			font-size: $text-size-s;
		}

		&__list {
			padding: 15px 0;
			display: flex;
			flex-wrap: wrap;
			align-items: center;
		}

		&__item {
			position: relative;
			margin-right: 25px;
			color: $c-gray;
			list-style: none;
			transition: 200ms ease-in-out 25ms;
			cursor: pointer;
			@extend .font-medium;

			&:before {
				content: "";
				position: absolute;
				width: 0;
				height: 2px;
				left: 0;
				right: 0;
				bottom: -10px;
				margin: 0 auto;
				background-color: $c-dark-green;
				transition: 200ms ease-in-out 25ms;
			}

			&--active {
				color: $c-dark-green;

				&:before {
					width: 35px;
				}
			}
		}

		&__search {
			display: flex;
			align-items: center;

			input {
				border: 0;
				display: block;
				outline: none;

				&::placeholder {
					color: $c-gray;
					text-overflow: ellipsis;
				}
			}

			svg {
				margin: 5px;
				width: 25px;
				height: 25px;
				flex-shrink: 0;
			}
		}

		&__new-cta {
			margin-left: 25px;
			color: $c-dark-green;
			font-size: $text-size-s;
			border-radius: 5px;
			border: 2px solid $c-dark-green;
			background-color: $c-white;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		&__label {
			margin: 0;
			padding-right: 10px;
			white-space: nowrap;
		}
	}
}
