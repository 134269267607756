.app-empty-data {
    .empty-data {
        height: 450px;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;

        &__text {
            margin-top: 25px;
        }
    }
}
