* {
	box-sizing: border-box;
}

#root {
	height: 100%;
	width: 100%;
	overflow: scroll;
	scrollbar-width: none;
	-ms-overflow-style: none;

	&::-webkit-scrollbar {
		display: none;
	}
}

html,
body {
	height: 100%;
}

body {
	color: $c-black;
	line-height: 1.2;
	font-size: $text-size-body;
	background-color: $c-body;
	font-family: sans-serif;
	@extend .font-normal;
}
