.page-notifications {
    padding: 40px;

    .notifications {
        //

        &__breadcrumb {
            padding: 0 0 25px;
            display: inline-flex;
            align-items: center;
            cursor: pointer;
        }

        &__text {
            margin: 0 5px;
            line-height: 1;
            color: $c-dark-green;
            @extend .font-medium;
        }

        &__header {
            padding: 0 0 20px;
            border-bottom: 1px solid lighten($c-gray, 37%);
        }

        &__title {
            margin: 0;
            color: $c-dark-navy;
            font-size: $text-size-xl;
            @extend .font-semibold;
        }

        &__date {
            margin: 25px 0;
            color: lighten($c-gray, 10%);
        }

        &__description {
            margin: 0;
        }
    }
}
