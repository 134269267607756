.page-product {
	padding: 40px 40px 100px;

	.product {
		//
		&__title {
			margin: 0;
			padding-bottom: 15px;
			color: $c-dark-navy;
			font-size: $text-size-xl;
			border-bottom: 1px solid $c-light-gray;
			@extend .font-semibold;
		}

		&__content {
			width: 100%;
			max-width: 768px;
			padding: 15px 0;
		}

		&__field {
			position: relative;
			width: 100%;
			margin-bottom: 15px;
			display: flex;
		}

		&__label {
			position: relative;
			width: 200px;
			height: 40px;
			margin: 8px 15px 8px 0;
			padding: 0.375rem 20px 0.375rem 0;
			color: $c-gray;
			white-space: break-spaces;
			display: flex;
			align-items: center;
			flex-shrink: 0;

			&--important {
				&:after {
					content: "*";
					color: $c-red;
				}
			}

			&:before {
				position: absolute;
				right: 5px;
				content: ":";
				display: block;
			}
		}

		&__button-container {
			width: 100%;
			max-width: 768px;
			margin-top: 45px;
			display: flex;
			align-items: flex-end;
			justify-content: flex-end;

			.app-cta {
				width: 200px;
				margin-left: 15px;
				min-width: inherit;
			}
		}
	}
}
