.page-purchase-transaction {
	padding: 40px;

	.purchase-transaction {
		//
		&__title {
			margin: 0;
			color: $c-dark-navy;
			font-size: $text-size-xl;
			@extend .font-semibold;
		}

		&__list {
			margin-bottom: 25px;
			padding: 15px 0;
			display: flex;
			flex-wrap: wrap;
			align-items: center;
		}

		&__item {
			position: relative;
			margin-right: 25px;
			margin-bottom: 20px;
			color: lighten($c-gray, 30%);
			list-style: none;
			transition: 200ms ease-in-out 25ms;
			cursor: pointer;
			@extend .font-medium;

			a {
				color: lighten($c-gray, 30%);
				text-decoration: none;
			}

			&:before {
				content: "";
				position: absolute;
				width: 0;
				height: 2px;
				left: 0;
				right: 0;
				bottom: -10px;
				margin: 0 auto;
				background-color: $c-dark-green;
				transition: 200ms ease-in-out 25ms;
			}

			&--active {
				a {
					animation: show-text-highlight 0.5s ease forwards;
				}

				&:before {
					animation: show-highlight 0.5s ease forwards;
				}
			}
		}

		&__header {
			width: 100%;
			padding: 20px 0;
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		&__description {
			margin: 0;
			color: $c-gray;
			font-size: $text-size-s;
		}

		&__new-cta {
			height: 41px;
			margin-left: 25px;
			color: $c-dark-green;
			font-size: $text-size-s;
			border-radius: 5px;
			border: 2px solid $c-dark-green;
			background-color: $c-white;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		&__label {
			margin: 0;
			padding-right: 10px;
			white-space: nowrap;
		}

		&__footer {
			margin-top: 45px;
		}
	}
}

@keyframes show-highlight {
	0% {
		width: 0;
	}
	100% {
		width: 35px;
	}
}

@keyframes show-text-highlight {
	0% {
		color: lighten($c-gray, 30%);
	}
	100% {
		color: $c-dark-green;
	}
}
