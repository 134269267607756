.app-bread-crumb {
	padding: 10px 0;
	margin-bottom: 15px;

	.bread-crumb {
		display: flex;

		&__item {
			margin: 0 10px 0 0;
			color: $c-dark-green;
			font-size: $text-size-s;
			@extend .font-semibold;
		}

		&__link {
			color: $c-gray;
			text-decoration: none;

			.bread-crumb__item {
				color: $c-gray;
			}
		}

		&__icon {
			margin-right: 5px;
		}
	}
}
