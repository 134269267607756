.app-page {
	padding: 15px;
}

.tooltip {
	&.show {
		opacity: 1 !important;
	}

	.tooltip-inner {
		color: $c-white;
		background-color: $c-cyan;
		border: 1px solid $c-cyan;
	}

	.tooltip-arrow {
		border-left: 6px solid transparent;
		border-right: 6px solid transparent;
		border-top: 6px solid $c-cyan;

		&::before {
			display: none;
		}
	}
}
