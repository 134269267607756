.app-side-nav {
	width: 245px;
	height: 100%;
	min-width: 50px;
	min-height: 100vh;
	padding: 11px 0;
	background-color: $c-dark-navy;
	scrollbar-width: none;
	-ms-overflow-style: none;
	flex-shrink: 0;

	@include max-width($scr-sm) {
		height: auto;
	}

	&::-webkit-scrollbar {
		display: none;
	}

	&--shrink {
		animation: shrink-nav 0.5s ease forwards;
	}
	&--expand {
		animation: expand-nav 0.5s ease forwards;
	}

	.side-nav {
		height: 100%;
		display: flex;
		align-items: stretch;
		flex-direction: column;

		&__slide-cta {
			position: relative;
			width: 100%;
			min-height: 50px;
			padding-right: 10px;
			display: flex;
			align-items: center;
			justify-content: flex-end;

			svg {
				width: 20px;
				height: 20px;
				color: $c-white;
			}
		}

		&__slide-cta-container {
			position: absolute;
			right: -15px;
			width: 35px;
			height: 35px;
			cursor: pointer;
			border-radius: 100px;
			background-color: $c-dark-green;
			box-shadow: rgba(38, 57, 77, 0.5) 0px 0px 30px -10px;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		&__list {
			width: 100%;
			padding: 0;
			flex-grow: 1;
			overflow-y: scroll;
			scrollbar-width: none;
			-ms-overflow-style: none;

			&::-webkit-scrollbar {
				display: none;
			}

			&--shrink {
				li {
					p {
						animation: hide-text 0.2s ease forwards;
					}
				}
			}

			&--expand {
				li {
					p {
						animation: show-text 1s ease forwards;
					}
				}
			}
		}

		&__sub-list {
			padding: 0;
			display: none;

			&--expand {
				display: block;
			}

			.side-nav__text {
				font-size: $text-size-xs;
			}
		}

		&__link {
			width: 100%;
			display: block;
			text-decoration: none;

			&:hover {
				text-decoration: none;
			}
		}

		&__item {
			position: relative;
			width: 100%;
			padding: 10px 15px;
			color: $c-dark-gray;
			cursor: pointer;
			list-style-type: none;
			transition: 0.1s linear;
			display: flex;
			align-items: center;

			&--active {
				color: $c-dark-green;
				@extend .font-semibold;

				path {
					fill: $c-dark-green;
					stroke: $c-dark-green;
				}
			}

			&--sub-active {
				color: $c-white;
				background-color: $c-dark-green;

				.side-nav__text {
					&:before {
						content: "";
						position: absolute;
						height: 100%;
						width: 40px;
						right: -30px;
						display: block;
						border-radius: 100px;
						background-color: $c-white;
					}
				}
			}
		}

		&__icon {
			width: 20px;
			height: 20px;
			flex-shrink: 0;
		}

		&__text {
			width: 100%;
			margin: 0 0 0 10px;
			font-size: $text-size-s;
			opacity: 1;
			white-space: nowrap;
			text-decoration: none;
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		&__version {
			color: $c-white;
			text-align: center;
			font-size: $text-size-xs;
		}
	}
}

@keyframes shrink-nav {
	0% {
		width: 245px;
	}
	100% {
		width: 50px;
	}
}

@keyframes expand-nav {
	0% {
		width: 50px;
	}
	100% {
		width: 245px;
	}
}

@keyframes show-text {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes hide-text {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}
