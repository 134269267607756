.app-allocate-points-modal {
	min-width: 560px;
	max-width: 768px;

	.allocate-points-modal {
		border: 0;
		border-radius: 10px;

		&__header {
			border: 0;

			.btn-close {
				outline: none;
				box-shadow: none;
			}
		}

		&__body {
		}

		&__image {
			width: 80px;
			margin: 0 auto;
			display: block;
		}

		&__title {
			margin-top: 20px;
			text-align: center;
			font-size: $text-size-l;
			@extend .font-semibold;
		}

		&__field {
			position: relative;
			width: 100%;
			padding: 0 25px;
			margin-bottom: 15px;
			display: flex;

			.app-input {
				&__input {
					background-color: $c-white;

					&:not(:disabled) {
						border: 1px solid $c-light-gray;
					}
				}
			}
		}

		&__label {
			position: relative;
			width: 200px;
			height: 40px;
			margin: 8px 15px 8px 0;
			padding: 0.375rem 20px 0.375rem 0;
			color: $c-gray;
			white-space: break-spaces;
			display: flex;
			align-items: center;
			flex-shrink: 0;

			&--important {
				&:after {
					content: "*";
					color: $c-red;
				}
			}

			&:before {
				position: absolute;
				right: 5px;
				content: ":";
				display: block;
			}
		}

		&__footer {
			padding-bottom: 40px;
			border: 0;
			display: flex;
			flex-wrap: nowrap;
			flex-direction: row;
			justify-content: center;

			.app-cta {
				width: 100%;
				max-width: 150px;
				min-width: 150px;
			}
		}
	}
}
