.app-tab {
    width: 100%;

    .tab {
        width: 100%;
        padding: 15px;
        margin-bottom: 1px;
        background-color: $c-dark-navy;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &__title {
            margin: 0;
            color: $c-white;
            font-size: $text-size-s;

            &--inactive {
                opacity: 0.3;
            }
        }

        &--category {
            margin-bottom: 0;
            background-color: lighten($c-gray, 35%);

            .tab__title {
                color: $c-black;
            }

            .tab__icon {
                color: $c-dark-navy;

                path {
                    fill: $c-dark-navy;
                }
            }
        }

        &--variation {
            margin-bottom: 0;
            padding-left: 35px;
            background-color: lighten($c-gray, 40%);

            .tab__title {
                color: $c-dark-navy;
            }

            .tab__icon {
                color: $c-dark-navy;

                path {
                    fill: $c-dark-navy;
                }
            }
        }

        &__edit-cta,
        &__caret-cta {
            width: 25px;
            border: 0;
            background-color: transparent;

            &--expand {
                margin-right: 25px;
            }
        }

        &__icon {
            color: $c-white;
            font-size: $text-size-xs;
            pointer-events: none;

            &--edit {
                path {
                    fill: $c-white;
                }
            }

            &--caret {
                transform: rotate(180deg);
            }
        }
    }
}
