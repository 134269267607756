.app-mobile-input {
	width: 100%;
	min-width: 300px;
	margin: 8px 0;

	.dropdown {
		&.show {
			.app-mobile-input__toggle {
				color: $c-gray;
				border-right: 1px solid $c-light-gray;

				&--active {
					color: $c-light-black;
				}
			}
		}
	}

	&__container {
		border-radius: 5px;
		background-color: $c-white;
		border: 1px solid $c-light-gray;
		display: flex;
		align-items: center;

		&--disabled {
			border: 0;
		}

		&--error {
			border: 1px solid $c-red;
		}
	}

	&__input {
		height: 40px;
		border: 0;
		outline: none;
		box-shadow: none;

		&:focus {
			box-shadow: none;
		}

		&::placeholder {
			color: $c-gray;
			font-size: $text-size-s;
		}

		&:disabled {
			border: 0;
			background-color: transparent;
		}
	}

	&__toggle {
		width: 100%;
		height: 40px;
		color: $c-gray;
		text-align: left;
		font-size: $text-size-s;
		background-color: $c-white !important;
		box-shadow: none;
		border: 0;
		border-radius: 0;
		border-right: 1px solid $c-light-gray;
		display: flex;
		align-items: center;
		justify-content: space-between;

		&:focus {
			outline: none !important;
			box-shadow: none !important;
		}

		&:hover,
		&:active {
			color: $c-gray;
			box-shadow: none !important;
			border-right: 1px solid $c-light-gray;
		}

		&:disabled {
			opacity: 1;
			color: $c-gray;
			background-color: $c-white;
		}

		&--active {
			color: $c-light-black;
		}
	}

	&__list {
		width: 100%;
		padding: 0;
		border: 2px solid $c-dark-green;
	}

	&__item {
		padding: 15px;
		color: $c-gray;
		font-size: $text-size-s;

		&:hover {
			color: $c-white;
			background-color: $c-dark-green;
		}
	}

	&__flag {
		width: 30px;
		margin-right: 15px;
	}

	&__text {
		margin: 2.5px 0 0;
		color: $c-gray;
		font-size: $text-size-xs;

		&--error {
			color: $c-red;
		}
	}
}
