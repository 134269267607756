.app-group-status-modal {
	min-width: 320px;

	.group-status-modal {
		border: 0;
		border-radius: 10px;

		&__header {
			border: 0;

			.btn-close {
				outline: none;
				box-shadow: none;
			}
		}

		&__icon {
			margin-bottom: 25px;
		}

		&__body {
			text-align: center;
		}

		&__title {
			font-size: $text-size-l;
			@extend .font-semibold;
		}

		&__description {
			font-size: $text-size-s;
		}

		&__footer {
			padding-bottom: 40px;
			border: 0;
			display: flex;
			flex-wrap: nowrap;
			flex-direction: row;
			justify-content: center;

			.app-cta {
				width: 100%;
				max-width: 150px;
				min-width: 150px;
			}
		}
	}
}
