/* -- Font Family -- */
@include font("Montserrat-ExtraLight", "../assets/fonts/montserrat/Montserrat-ExtraLight");
@include font("Montserrat-Light", "../assets/fonts/montserrat/Montserrat-Light");
@include font("Montserrat-Thin", "../assets/fonts/montserrat/Montserrat-Thin");
@include font("Montserrat-Regular", "../assets/fonts/montserrat/Montserrat-Regular");
@include font("Montserrat-Medium", "../assets/fonts/montserrat/Montserrat-Medium");
@include font("Montserrat-SemiBold", "../assets/fonts/montserrat/Montserrat-SemiBold");
@include font("Montserrat-Bold", "../assets/fonts/montserrat/Montserrat-Bold");
@include font("Montserrat-ExtraBold", "../assets/fonts/montserrat/Montserrat-ExtraBold");
@include font("Montserrat-Black", "../assets/fonts/montserrat/Montserrat-Black");

.font-exlight {
	font-family: Montserrat-ExtraLight, Roboto, sans-serif;
}

.font-light {
	font-family: Montserrat-Light, Roboto, sans-serif;
}

.font-thin {
	font-family: Montserrat-Thin, Roboto, sans-serif;
}

.font-normal {
	font-family: Montserrat-Regular, Roboto, sans-serif;
}

.font-medium {
	font-family: Montserrat-Medium, Roboto, sans-serif;
}

.font-semibold {
	font-family: Montserrat-SemiBold, Roboto, sans-serif;
}

.font-bold {
	font-family: Montserrat-Bold, Roboto, sans-serif;
}

.font-exbold {
	font-family: Montserrat-ExtraBold, Roboto, sans-serif;
}

.font-black {
	font-family: Montserrat-black, Roboto, sans-serif;
}
