.app-notification-modal {
    width: 100%;
    min-width: 768px;
    max-width: inherit;
    margin: inherit;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;

    &__backdrop {
        background-color: transparent;
    }

    .notification-modal {
        margin: 65px 30px;
        max-width: 360px;
        border: 0;
        overflow: hidden;
        border-radius: 8px;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

        &__header {
            padding: 10px;
            text-align: center;
            background-color: $c-dark-navy;
        }

        &__text {
            margin: 0;
            color: $c-white;
            font-size: $text-size-s;
        }

        &__body {
        }

        &__list {
            margin: 0;
            padding: 0;
            list-style: none;
            max-height: 77vh;
            overflow-y: scroll;
            scrollbar-width: none;
            -ms-overflow-style: none;

            &::-webkit-scrollbar {
                display: none;
            }
        }

        &__item {
            &:hover {
                background-color: $c-light-navy;
            }
        }

        &__icon {
            position: relative;
            width: 45px;
            height: 45px;
            border-radius: 45px;
            margin-right: 10px;
            background-color: $c-cyan;
            flex-shrink: 0;
            display: flex;
            align-items: center;
            justify-content: center;

            &::before {
                content: "";
                display: block;
                position: absolute;
                top: 2.5px;
                right: 5px;
                width: 5px;
                height: 5px;
                border-radius: 10px;
                background-color: $c-red;
            }

            &--read {
                background-color: lighten($c-gray, 30%);

                &::before {
                    display: none;
                }
            }
        }

        &__content {
            padding: 10px;
            margin: 0 10px;
            border-bottom: 1px solid lighten($c-gray, 37%);
            cursor: pointer;
            display: flex;
            align-items: center;

            &--no-border {
                border-bottom: 0;
            }
        }

        &__date {
            margin: 0;
            padding: 8px 10px;
            font-size: $text-size-s;
            background-color: lighten($c-gray, 37%);
        }

        &__time {
            margin: 0;
            color: lighten($c-gray, 10%);
            font-size: $text-size-xs;
        }

        &__title {
            width: 260px;
            margin: 0 0 3px;
            font-size: $text-size-s;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            @extend .font-medium;
        }

        &__arrow {
            flex-shrink: 0;
            color: lighten($c-gray, 10%);
        }

        &__loading {
            padding: 10px;
            font-size: $text-size-s;
            color: lighten($c-gray, 10%);
            animation: fadding 2.5s ease-in-out infinite;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &__read-more {
            width: 100%;
            border: 0;
            padding: 8px;
            color: $c-gray;
            font-size: $text-size-s;
            background-color: lighten($c-gray, 37%);
        }
    }
}

@keyframes fadding {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
