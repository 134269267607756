.page-groups {
    padding: 40px;
    min-height: calc(100vh - 75px);
    display: flex;
    flex-direction: column;

    .groups {
        margin-bottom: 35px;
        
        &__header {
            width: 100%;
            padding: 20px 0;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        &__title {
            margin: 0;
            color: $c-dark-navy;
            font-size: $text-size-xl;
            @extend .font-semibold;
        }

        &__description {
            margin: 0;
            color: $c-gray;
            font-size: $text-size-s;
        }

        &__new-cta {
            height: 41px;
            margin-left: 25px;
            color: $c-dark-green;
            font-size: $text-size-s;
            border-radius: 5px;
            border: 2px solid $c-dark-green;
            background-color: $c-white;
            display: flex;
            align-items: center;
            justify-content: center;

            &:disabled {
                color: lighten($c-gray, 30%);
                border: 2px solid lighten($c-gray, 30%);
            }
        }

        &__label {
            margin: 0;
            padding-right: 10px;
            white-space: nowrap;
        }

        .accordion {
            &__wrapper {
                width: 100%;
                display: flex;
                align-items: center;
            }

            &__categories {
                padding-left: 34px;
            }

            &__activate-cta {
                margin-right: 15px;
                cursor: pointer;

                &--deactivate {
                    circle {
                        fill: lighten($c-gray, 35%);
                        stroke: lighten($c-gray, 35%);
                    }
                }
            }

            &__add-cta {
                width: 100%;
                padding: 15px 12px;
                border: 0;
                background-color: transparent;
                display: flex;
                align-items: center;

                &:before {
                    content: "";
                    display: block;
                    width: 15px;
                    height: 15px;
                    margin-right: 10px;
                    background-size: contain;
                    background-position: center;
                    background-repeat: no-repeat;
                    background-image: url("../../assets/images/add-icon.svg");
                }

                &--active {
                    background-color: $c-dark-green;

                    &:before {
                        background-image: url("../../assets/images/add-white-icon.svg");
                    }

                    .accordion__text {
                        color: $c-white;
                    }
                }
            }

            &__text {
                margin: 0;
                color: $c-dark-green;
                font-size: $text-size-s;
            }
        }
    }

    .app-pagination {
        margin-top: auto;
    }
}
