.app-alert-message {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	width: 100%;
	pointer-events: none;
	z-index: 999999999999;

	.alert-message {
		max-height: calc(100% - 20px);
		margin: 10px;
		padding: 12.5px;
		background-color: $c-green;
		pointer-events: auto;
		display: flex;

		&--error {
			background-color: $c-red;
		}

		&__text {
			width: 100%;
			margin: 0;
			color: $c-white;
			font-size: $text-size-s;
			white-space: normal;
			overflow-y: scroll;
			scrollbar-width: none;
			-ms-overflow-style: none;

			&::-webkit-scrollbar {
				display: none;
			}

			&--hide {
				white-space: nowrap;
				text-overflow: ellipsis;
			}

			@extend .font-medium;
		}

		&__icon {
			margin: 0 5px;
			color: $c-white;
			cursor: pointer;
			flex-shrink: 0;

			&--active {
				transform: rotate(-90deg);
			}
		}

		&__close {
			margin: 0 5px 0 0;
			color: $c-white;
			cursor: pointer;
			flex-shrink: 0;
		}
	}
}
